<template>
  <div class="main">
	  <loading color="#1989fa" vertical>正在登录...</loading>
  </div>
</template>
<script>
// import { getUserId } from '@/api/service'
import {IS_DEBUG} from '/env'
import {Dialog, Loading} from 'vant';
import {authByCode, loginByUserid} from '@/api/login'
import Cookies from "js-cookie";
export default {
  name: 'Home',
  components: {
	  Loading
  },
  data() {
    return {
      userId: '', // 用户id
    }},
  created() {

  },
  mounted:function(){
    if(IS_DEBUG) {
        this.testLogin()
        return
    }
      // sessionStorage.setItem("token",'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJidWJibGUiLCJyb2xlIjoiWzEsNSw0XSIsImlzcyI6InJlblJ1aSIsIm5hbWUiOiLnjovno4rlvLoiLCJkZXB0SWQiOjk1NSwiZXhwIjoxNjkyNDI1MzUyLCJ1c2VySWQiOiJXYW5nbGVpcWlhbmcifQ.gYwgaKV9XawK1RYCs9qyUD2--E8W0T9Lt6IsOGGBOhM');
	  var token = sessionStorage.getItem("token");
	  if (undefined == token ){
		  if (this.$route.query.code == undefined){
			  // 获取code的url拼接
              let nowUrl = window.location.href;
              let backurl = encodeURIComponent(nowUrl)
			  const url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf93ebffd5d31aa7a&redirect_uri=' + encodeURIComponent(backurl) + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
			  console.log(url)
			  window.location.href = url
		  } else {
			  // 得到授权code
			  var code = this.$route.query.code
              this.authOnCode(code)
			  console.log(code)
		  }
	  }
  },
  methods: {
      testLogin(){
          const query = this.$route.query
          let userid = query.userId?query.userId:'MaHui'
          loginByUserid({userId: userid}).then((res) => {
                  console.log(res);
                  sessionStorage.setItem("userId", userid);
                  sessionStorage.setItem("token", res.data.token);
                  sessionStorage.setItem("username", res.data.userName);
                  Cookies.set('userId', userid)
              let path = sessionStorage.getItem("redirect");
              let query = sessionStorage.getItem("search");
              if (query != undefined){

                  this.$router.push({path: path, query: JSON.parse(query)})
              } else {
                  this.$router.push({path: path})
              }
          })
      },
	  authOnCode(code){
		  authByCode({code: code}).then((res) => {
			  let userid = res.data;
			  console.log(userid);

			  loginByUserid({userId: userid}).then((res) => {
				  console.log(res);
                  sessionStorage.setItem("userId", userid);
				  sessionStorage.setItem("token", res.data.token);
				  sessionStorage.setItem("username", res.data.userName);
                  Cookies.set('userId', userid)
				  // 得到用户校色
				  let roleIds = JSON.parse(res.data.roleId);
				  // 调转页面
				  let path = sessionStorage.getItem("redirect");
				  let query = sessionStorage.getItem("search");
				  // 判断是不是进入采购审批 或者 提货审批
                  /*if(path){
                      if(path.indexOf('examine') === -1 && path.indexOf('approve') === -1 && path.indexOf('shop') === -1) {
                          // 判断是否是库管
                          if (roleIds.indexOf(2) == -1){
                              this.$dialog({
                                  title:"提示",
                                  message:"您不是库管，没有登录权限"
                              })
                              return
                          }
                      }
                  }else{
                      // 判断是否是库管
                      if (roleIds.indexOf(2) == -1){
                          this.$dialog({
                              title:"提示",
                              message:"您不是库管，没有登录权限"
                          })
                          return
                      }
                  }*/
				  // 默认跳转到采购列表
                  if(!path){
                      this.$router.push({path: '/demand'})
                      return
                  }
				  if (query != undefined){

					  this.$router.push({path: path, query: JSON.parse(query)})
				  } else {
					  this.$router.push({path: path})
				  }
			  })
		  })
	  }
  }
}
</script>

<style scoped>
  .main{
    background-color:#ffffff;
    padding: 20px;
    min-height: 100vh;
  }
</style>
